export const BlogCategoryList = [
    {
        id: 1,
        title : "Photography",
    },
    {
        id: 2,
        title : "Web-design",
    },
    {
        id: 3,
        title : "Illustration",
    },
    {
        id: 4,
        title : "Marketing",
    }
]