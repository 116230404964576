// BlogPage.js
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { blogList } from "./Json/BlogsList";

const BlogPage = () => {
  const { blogId } = useParams();

  const blog_id = Number(blogId);
 

  const getBlogDetails = useMemo(() => {
    return blogList[blog_id-1] || null;
  }, [blogList, blog_id]);
  if (!blogId) {
    return null;
  }
  return (
    <div>
      <Container>
        <Row className="my-5">
          <Col lg={8} md={8} sm={12}>
            <div className="text-center border-bottom py-2 mb-5">
              <h3>{getBlogDetails?.title}</h3>
              <span className="text-center">{getBlogDetails?.date} {getBlogDetails?.name} </span>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: getBlogDetails?.para1 }}
              className="my-2"
            />
            <img src={getBlogDetails?.img} alt="" className="my-2 w-100" />
            <div
              dangerouslySetInnerHTML={{ __html: getBlogDetails?.para2 }}
              className="my-2"
            />
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="catageries-of-singleblog">
              <h3 className="mb-3">Categories</h3>
              <Link className="text-decoration-none">
                <p>Digital Marketing</p>
              </Link>
              <Link className="text-decoration-none">
                <p>Conventional Marketing</p>
              </Link>
              <Link className="text-decoration-none">
                <p>Market Research</p>
              </Link>
              <Link className="text-decoration-none">
                <p>Marketing Hacks</p>
              </Link>
              <Link className="text-decoration-none">
                <p>Web Design</p>
              </Link>
              <Link className="text-decoration-none">
                <p>Photography</p>
              </Link>
              <Link className="text-decoration-none">
                <p>Graphics and Design</p>
              </Link>
              <Link className="text-decoration-none">
                <p>Social Media</p>
              </Link>
            </div>

            <div className="recentPost-of-singleblog my-5">
              <h3 className="mb-3">Recent Posts</h3>
              <div>
                <Link className="text-decoration-none">
                  <img src={getBlogDetails?.img1} className="w-100" alt="" />
                  <p>{getBlogDetails?.recent1}</p>
                </Link>
              </div>
              <div className="my-3">
                <Link className="text-decoration-none">
                  <img src={getBlogDetails?.img2} className="w-100" alt="" />
                  <p>{getBlogDetails?.recent2}</p>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-between mb-5">
          <Link to={`/blog/${blog_id !== 1 ? Number(blogId ?? 0) -1  : 1}`}>
            <Button variant="info">Previous</Button>
          </Link>
          <Link
            to={`/blog/${
              blogList?.length !== blog_id
                ? Number(blogId ?? 0) + 1
                : blogList?.length
            }`}
          >
            <Button disabled={blogList?.length === blog_id} variant="info">
              Next
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default BlogPage;
