import "./App.css";
import {
  Route,
  Routes,
} from "react-router-dom";
import Home from "./Componants/Home/Home.js";
import Navbar from "./Componants/Navbar/Navbar";
import Footer from "./Componants/Footer/Footer";
import Project from "./Componants/Project/Project";
import Plan from "./Componants/Plan/Plan";
import Blog from "./Componants/Blog/Blog";
import Contact from "./Componants/Contact Us/Contact";
import BlogPageExpand1 from "./Componants/Blog/BlogPageExpand1.js";
import BlogPageExpand2 from "./Componants/Blog/BlogPageExpand2.js";
import BlogPage from "./Componants/Blog/BlogPage.js";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project" element={<Project />} />
        <Route path="/plan" element={<Plan />} />
        <Route extact path="/blog" exact element={<Blog />} />
        <Route extact path="/blog1" exact element={<BlogPageExpand1 />} />
        <Route extact path="/blog2" exact element={<BlogPageExpand2 />} />
        <Route extact path="/contact" exact element={<Contact />} />
        <Route exact path="/blog/:blogId" element={<BlogPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
