import React from "react";
import { Link } from "react-router-dom";
import "./Blog.css";

function BlogPageCard({ title, name, content, date, img, path }) {
  return (
    <div>
      <div className="blog-card p-4">
        <img src={img} className="w-100 " alt="" />
        <div className="border-bottom py-2 ">
          <span className="card-blog-title">{title}</span>
          <br />
          <span className="text-gray-blog">
            {" "}
            by <span> {name}</span>
            {date && `| ${date}`}
          </span>
        </div>
        <p className="mt-3 blog-content">
          {content.length > 50 ? (
            <div>
              {`${content.slice(0, 50)}... `}
               <Link to={path} > Read more</Link>
              </div>
          ) : content}
        </p>{" "}
      </div>
    </div>
  );
}

export default BlogPageCard;
