import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogPageCard from "./BlogPageCard";
import "./Blog.css";
import { blogList } from "./Json/BlogsList";
import { BlogCategoryList } from "./Json/BlogCategoryList";

function Blog() {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [poularPage, setPopularPage] = useState(1);
  // Create an instance of URLSearchParams to extract query params
  const searchParams = new URLSearchParams(location.search);
  // Get the categoryId from the query string
  const categoryId = searchParams.get("categoryId");
  const List = categoryId
    ? blogList?.filter((item) => item?.categoryId == categoryId)
    : blogList;
  const hasNextPage = Number(List?.length ?? 0) / 2 > page;

  const popularBlogs = blogList?.filter((item) => !!item?.popular);
  const hasNextPolularPage = Number(popularBlogs?.length ?? 0) /2  > poularPage;

  return (
    <div>
      <Container>
        <Row>
          <Col lg={8} md={12} sm={12} className="p-3">
            <Row className="justify-content-center">
              {List?.slice(0, page * 2).map((entry) => (
                <Col lg={6} md={6} sm={12} className="my-4" key={entry.id}>
                  <Link
                    to={`/blog/${entry?.id}`}
                    className="text-decoration-none"
                  >
                    <BlogPageCard
                      img={entry.img}
                      name={entry.name}
                      title={entry.title}
                      content={entry.content}
                      path={`/blog/${entry?.id}`}
                    />
                  </Link>
                </Col>
              ))}
              {!!List?.length ? (
                <div className="d-flex justify-content-between mb-5">
                  <button
                    type="button"
                    class="btn btn-dark"
                    disabled={page === 1}
                    onClick={() => {
                      setPage((prev) => prev - 1);
                    }}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    class="btn btn-dark"
                    disabled={!hasNextPage}
                    onClick={() => {
                      setPage((prev) => prev + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
              ) : (
                <div>No blog found</div>
              )}
            </Row>
          </Col>
          <Col lg={4} md={12} sm={12} className="p-5">
            <div className="blog-cataguries mb-5">
              <h6 className="border-bottom py-2">Blog Categories</h6>
              {BlogCategoryList?.map((category) => (
                <Link
                  to={`/blog/?categoryId=${category?.id}`}
                  className="text-decoration-none"
                >
                  {" "}
                  <p>* {category?.title}</p>{" "}
                </Link>
              ))}
            </div>

            <div className="blog-popular-post mb-5">
              <h6 className="border-bottom py-2">Popular Posts</h6>
              <Row>
                {popularBlogs?.slice(0, poularPage*2)?.map((item) => (
                  <>
                    <Col lg={6} md={6} sm={12} className="my-2">
                      <div>
                        <img
                          src={item?.img}
                          alt={item?.title}
                          className="popular-post w-100"
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className="my-2">
                      <div className="popular-post-content ">
                        <Link
                          className="text-decoration-none"
                          to={`/blog/${item?.id}`}
                        >
                          <span>{`${item?.title.slice(0, 20)}... `} </span>
                        </Link>
                        <div className="popular-post-date">{item?.date}</div>
                      </div>
                    </Col>
                   
                  </>
                ))}
                 {hasNextPolularPage && (
                      <div>
                        <button
                          type="button"
                          class="btn btn-dark"
                          onClick={() => {
                            setPopularPage((prev) => prev + 1);
                          }}
                        >
                          More
                        </button>
                      </div>
                    )}

                {/* <Col lg={6} md={6} sm={12} className="my-2">
                  <div>
                    <img
                      src="https://cdn.pixabay.com/photo/2024/02/26/10/58/rose-8597693_1280.jpg"
                      alt=""
                      className="popular-post w-100"
                    />
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} className="my-2">
                  <div className="popular-post-content ">
                    <Link className="text-decoration-none">
                      <span>Lorem ipsum dolor sit amet </span>
                    </Link>
                    <div className="popular-post-date">25 Feb</div>
                  </div>
                </Col> */}
              </Row>
            </div>

            {/* <div className="blog-text mb-5">
              <h6 className='border-bottom py-2'>
              Text
              </h6>
              <p>
              Marketing and Designs are two sides of the same coin. Both are indespensable. Without a proper marketing Strategy and consumer analytics your businesses is incomplete. Likewise marketing campaigns without structured designs are likely to get into mess.
              </p>
            </div>

            <div className="blog-recent-post mb-5">
              <h6 className='border-bottom py-2'>
              Text
              </h6>
              <div>
              <span>Maria on :- <Link>Inbound Marketing</Link></span> <br />
              <span>John on :- <Link>Together</Link></span> <br />
              <span>Andy on :- <Link>Graphics</Link></span> <br />
              <span>Mark on :- <Link>How to Capture perfect stills</Link></span> <br />
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>

      {/* <li><Link to="/blog/page1">Blog Page 1</Link></li>
        <li><Link to="/blog/page2">Blog Page 2</Link></li> */}
    </div>
  );
}

export default Blog;
